// extracted by mini-css-extract-plugin
export var linkhover = "blog-module--linkhover--tBuFq";
export var bottom = "blog-module--bottom--7WXRp";
export var left = "blog-module--left--miB2h";
export var callout = "blog-module--callout--SsLp-";
export var right = "blog-module--right--BJ5Og";
export var link = "blog-module--link--wq1AR";
export var top = "blog-module--top--MN0JD";
export var logolink = "blog-module--logolink--Os--O";
export var lefttop = "blog-module--lefttop--VtNox";
export var logo = "blog-module--logo--nA0Ms";
export var blogtitle = "blog-module--blogtitle--4DIBx";
export var leftbottom = "blog-module--leftbottom--D1r5K";
export var leftblurb = "blog-module--leftblurb--7e17w";
export var request = "blog-module--request--xhAJI";
export var bg = "blog-module--bg--zWO2w";
export var bgxl = "blog-module--bgxl--gvEjr";
export var bgdesktop = "blog-module--bgdesktop--N62zR";
export var bgtablet = "blog-module--bgtablet--OK1RV";
export var bgmobile = "blog-module--bgmobile--BkWPM";
export var servicename = "blog-module--servicename--jWHle";
export var heading = "blog-module--heading--RMkP2";
export var blurb = "blog-module--blurb--FWQsq";
export var icon = "blog-module--icon--xwyK1";
export var postcontainer = "blog-module--postcontainer--oSjz5";
export var post = "blog-module--post--u29Uf";
export var title = "blog-module--title--TdY0b";
export var date = "blog-module--date--+WPQf";
export var excerpt = "blog-module--excerpt--Bytvq";
export var readmore = "blog-module--readmore--cWizh";
export var hovericon = "blog-module--hovericon--er9hE";
export var servicecontent = "blog-module--servicecontent--OWVke";
export var contentleft = "blog-module--contentleft--BcPCW";